// import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <div className='error-section'>
            Error.js
        </div>
    );
};
export default Error;
